import * as React from 'react';
import PageSeo from '../components/Seo/PageSeo';
import { CalloutLong, PageSeparator, TwoColumnLayout, CalloutCardSecond } from '../components';
import PhoneIcon from '../assets/images/phone-icon.svg';
import MedicationIcon from '../assets/images/medication-icon.svg';
import styled from 'styled-components';
import PageSection from '../components/PageSection';
import DNAManA from '../assets/images/DNA-Man-a.png';
import DNAManB from '../assets/images/DNA-Man-b.png';
import DNAManAMobile from '../assets/images/DNA-Man-a-mobile.png';
import DNAManBMobile from '../assets/images/DNA-Man-b-mobile.png';

// SEO INFORMATION
const pageMeta = {
  title: 'WELIREG® (belzutifan) | Official Site for Health Care Professionals',
  keywords: 'welireg, belzutifan',
  description:  'Health care professionals may find information about WELIREG® (belzutifan), approved for the treatment of appropriate adult patients with advanced renal cell carcinoma (RCC) and von Hippel-Lindau disease.',
  schemaJsonLD: [
    `{"@context":"http://schema.org/","url":"https://www.welireghcp.com/","@type":"ApprovedIndication","name":"WELIREG™","alternateName":"belzutifan","description":"WELIREG is indicated for the treatment of adult patients with von Hippel-Lindau (VHL) disease who require therapy for associated renal cell carcinoma (RCC), central nervous system (CNS) hemangioblastomas, or pancreatic neuroendocrine tumors (pNET), not requiring immediate surgery."}`,
    `{"@context":"http://schema.org/","url":"https://www.welireghcp.com/","@type":"ApprovedIndication","name":"WELIREG™","alternateName":"belzutifan","description":"WELIREG is indicated for the treatment of adult patients with advanced renal cell carcinoma (RCC) following a programmed death receptor-1 (PD-1) or programmed death-ligand 1 (PD-L1) inhibitor and a vascular endothelial growth factor tyrosine kinase inhibitor (VEGF-TKI)."}`,
    `{"@context":"http://schema.org/","url":"https://www.welireghcp.com/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"http://schema.org/","url":"https://www.welireghcp.com/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","manufacturer":"Merck"}`
  ]
}

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const TwoColumnLayoutIndex = styled(TwoColumnLayout)`
  & > div > div {
    height: 100% !important;
  }

`;

const calloutCardSecondPropsA = {
  title: 'WELIREG for certain patients with previously treated advanced RCC',
  //description: "Consider WELIREG for your appropriate adult patients as early as the second line following treatment with both anti–PD-1/L1 and VEGF-TKI therapies.",
  description: <>Consider WELIREG for your appropriate adult patients as early as the second line following treatment with both anti&#8288;&ndash;&#8288;PD&#8288;-&#8288;1&#8288;/&#8288;L1 and VEGF&#8288;-&#8288;TKI therapies.</>,
  linkText: 'Learn More',
  linkUrl: '/advanced-renal-cell-carcinoma/',
  backgroundImage: DNAManA,
  backgroundImageMobile: DNAManAMobile,
};

const calloutCardSecondPropsB = {
  title: 'WELIREG for patients with certain VHL disease&#8288;&ndash;&#8288;associated tumors',
  description: <>Consider WELIREG for your appropriate adult patients with VHL disease&#8288;&ndash;&#8288;associated RCC, CNS, or pNET tumors, not requiring immediate surgery.</>,
  linkText: 'See the Data',
  linkUrl: '/von-hippel-lindau/',
  backgroundImage: DNAManB,
  backgroundImageMobile: DNAManBMobile,
};

const calloutLongA = {
  title: 'Connect with a Merck Associate',
  icon: PhoneIcon,
  linkText: 'Ask Merck',
  linkUrl: 'https://www.askmerck.com/',
  externalInvertedButtons: true,

};

const calloutLongB = {
  title: 'The Merck Access Program',
  icon: MedicationIcon,
  linkText: 'Visit Now',
  linkUrl: 'https://www.merckaccessprogram-welireg.com/hcp/',
  definitions: <>CNS = central nervous system; PD&#8288;-&#8288;1/L1 = programmed death receptor&#8288;-&#8288;1 (PD&#8288;-&#8288;1)/programmed death&#8288;-&#8288;ligand 1 (PD&#8288;-&#8288;L1); pNET = pancreatic neuroendocrine tumors; RCC = renal cell carcinoma; VEGF&#8288;-&#8288;TKI = vascular endothelial growth factor tyrosine kinase inhibitor; VHL = von Hippel&#8288;-&#8288;Lindau.</>,
};

const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/" />
      </PageSeo>

      <PageSeparator topPadding='20px' topPaddingMobile='13px' />

      <StandardSection>
        <TwoColumnLayoutIndex gapSize='20px'>
          <CalloutCardSecond {...calloutCardSecondPropsA} />
          <CalloutCardSecond className="vhl-block" {...calloutCardSecondPropsB} />
        </TwoColumnLayoutIndex>
      </StandardSection>

      <PageSeparator topPadding='16px' topPaddingMobile='12px' />

      <StandardSection>
        <CalloutLong {...calloutLongA}>
          <p>
            Merck is committed to helping you get answers to your questions. Connect with a Merck Associate now. This service is for US health care professionals.
          </p>
        </CalloutLong>
      </StandardSection>

      <PageSeparator topPadding='16px' topPaddingMobile='12px' />

      <StandardSection>
        <CalloutLong {...calloutLongB}>
          <p>
            For access, coverage, and dispensing information, visit The Merck Access Program.
          </p>
        </CalloutLong>
      </StandardSection>
    </>
  );
};


export default IndexPage;
